<template>
  <div class="main">
    <div class="main__body">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>

<style lang="scss"></style>
